<template>
  <div class="errorBox flex flex-c flex-m">
    <div class="error-content">
      <div class="error-title">资源不存在</div>
      <div class="error-text">请重新选择，{{ num }}s后自动跳转</div>
      <div class="error-btn" @click="$router.push('/home')">返回</div>
    </div>
    <div class="error-img-r">
      <img
        src="@/assets/images/pic_empty_resources@2x.png"
        alt="海亮教育"
        title="海亮教育"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Resource",
  components: {},
  data() {
    return {
      num: 3,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.timer = setInterval(() => {
      if (this.num > 0) {
        this.num--;
      } else {
        this.$router.push("/home");
        clearInterval(this.timer);
        this.timer = null;
      }
    }, 1000);
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.errorBox {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  .error-content {
    margin-right: 152px;
  }
  .error-img-l {
    width: 110px;
  }
  .error-title {
    height: 38px;
    font-size: 22px;
    font-weight: 600;
    color: #282828;
    line-height: 38px;
    letter-spacing: 2px;
    margin-top: 15px;
    margin-bottom: 4;
  }
  .error-text {
    font-size: 16px;
    font-weight: 400;
    color: #c2c2d1;
    line-height: 22px;
    margin-bottom: 61px;
  }
  .error-btn {
    width: 120px;
    height: 50px;
    background: @themeBlue;
    box-shadow: 0px 5px 15px 0px rgba(96, 73, 255, 0.14);
    border-radius: 27px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
  .error-img-r {
    width: 568px;
    padding-left: 50px;
  }
  img {
    display: block;
    max-width: 100%;
  }
}
</style>
